<template lang="pug">
Dropdown#shadow(
  :label="labelOverride || $t('shadow')"
  layout="col"
  :items="shadowItems"
  v-model="shadowValue"
  :editMobile="editMobile"
)
  MoreOrLess.mt-3(hideToggler :open="isMoreOrLessOpen")
    OmColorInput(
      :label="$t('shadowColor')"
      :property="coloringProperty"
      :typeOfComponent="typeOfComponent"
      layout="col"
      :editMobile="editMobile"
      dsAllowed
    )
</template>
<script>
  import { mapState } from 'vuex';
  import Dropdown from '../Dropdown/Dropdown.vue';
  import MoreOrLess from '../MoreOrLess.vue';
  import ColorInput from '../ColorInput/ColorInput.vue';

  export default {
    components: {
      Dropdown,
      MoreOrLess,
      ColorInput,
    },
    props: {
      editMobile: {
        type: Boolean,
        default: false,
      },
      labelOverride: {
        type: String,
      },
      property: {
        type: String,
      },
      typeOfComponent: {
        type: String,
      },
      value: {
        type: String,
      },
      layout: {
        type: String,
        default: 'row',
        options: ['row', 'col'],
        validator: (value) => {
          return ['row', 'col'].includes(value);
        },
      },
      coloringProperty: {
        type: String,
      },
      textShadow: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      ...mapState(['mobilePreview']),
      shadowItems() {
        const baseShadowItems = [
          { value: 'none', text: this.$t('none') },
          this.textShadow
            ? { value: 'light', text: this.$t('light') }
            : { value: 'normal', text: this.$t('normal') },
          { value: 'medium', text: this.$t('medium') },
          { value: 'large', text: this.$t('large') },
        ];

        if (this.mobilePreview && this.editMobile) {
          baseShadowItems.unshift({ value: null, text: this.$t('default') });
        }

        return baseShadowItems;
      },
      isMoreOrLessOpen() {
        return !['none', null].includes(this.shadowValue);
      },
      shadowValue: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit('input', value);
        },
      },
    },
  };
</script>
