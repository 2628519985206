<template lang="pug">
.ds-input-group.design-system
  PaneLayout(gap="4px")
    template(#left)
      .d-flex.align-items-center
        .ds-input-group-label {{ label }}
        DeviceSelector(v-if="editMobile" :hasViewText="false")
    template(#right)
      .d-flex.w-100
        .d-flex
          .ds-input-group-element(v-for="(key, index) in keys" :key="key.name")
            .ds-input-group-element-label {{ $t(key.name) }}
            input.ds-input-group-input.w-100(
              type="number"
              :value="key.disabled ? '' : key.value"
              :min="allowNegative ? null : 0"
              step="1"
              :disabled="key.disabled"
              @input="setValue(key.name, $event)"
              :placeholder="getPlaceholder(index)"
              :class="{ error: key.error }"
            )
        .ds-input-group-element.ds-input-group-element-link
          om-button.ds-input-group-element-input-link(
            icon="link-alt"
            iconOnly
            ghost
            iconSize="1.625em"
            :title="$t('allSides')"
            :selected="allKeys"
            @click="toggleAllKeys"
          )
</template>

<script>
  import { debounce } from 'lodash-es';
  import OmButton from '@/components/Elements/Button';
  import PaneLayout from '../PaneLayout.vue';

  export default {
    name: 'InputGroup',
    components: {
      PaneLayout,
      OmButton,
      DeviceSelector: () => import('@/editor/components/sidebar/components/DeviceSelector.vue'),
    },
    props: {
      keys: {
        type: Array,
        default: () => [
          { name: 'top', value: 0, disabled: false },
          { name: 'right', value: 0, disabled: false },
          { name: 'bottom', value: 0, disabled: false },
          { name: 'left', value: 0, disabled: false },
        ],
      },
      placeholders: {
        type: Array,
        default: null,
      },
      label: {
        type: String,
      },
      allowNegative: {
        type: Boolean,
        default: false,
      },
      allKeysToggled: {
        type: Boolean,
        default: false,
      },
      editMobile: {
        type: Boolean,
        default: false,
      },
    },
    data: () => ({
      allKeys: false,
    }),
    mounted() {
      if (this.allKeysToggled) {
        this.allKeys = true;
      }
    },
    methods: {
      setValue: debounce(function (key, event) {
        const value = event.target.value;
        this.$emit('input', { key, value, allSides: this.allKeys });
      }, 120),

      toggleAllKeys() {
        this.allKeys = !this.allKeys;
        this.$emit('all-keys-change', this.allKeys);
        if (this.allKeys) {
          const firstValue = this.keys[0]?.value ?? 0;
          this.keys.forEach(({ key }) => {
            this.$emit('input', { key, value: firstValue, allSides: this.allKeys });
          });
        }
      },
      getPlaceholder(index) {
        return this.placeholders?.[index] ?? '';
      },
    },
  };
</script>
<style lang="sass">
  @import '../../../sass/variables/_colors.sass'

  .ds-input-group
    &-label
      color: $om-dark-grey-3
      font-size: 0.75rem
      line-height: 2
    &-all-sides
      display: flex
      align-items: center
      margin-bottom: 0.875rem
    &-element
      display: flex
      flex-direction: column-reverse
      gap: 4px
      &-link
        display: flex
        flex-direction: column
        margin-left: 0.25rem
      &-input-link
        color: #696d72
        padding: .25rem !important
        &.selected
          background-color: transparent !important
          color: $om-orange
        > div
          line-height: 1.625rem
        &.active
          background: var(--brand-primary-color)
          border-color: var(--brand-primary-color)
          color: white
      &-label
        font-size: 0.75rem
        color: $om-dark-grey-3

      input
        border: 1px solid $om-light-grey2
        padding: .5rem
        font-size: .75rem
        line-height: 1.2
        color: $om-dark-grey-3
        border-right: 0
        &:disabled
          color: $om-light-grey2
          background: $om-light-grey2
        &.error
          border: 1px solid red !important
      &:first-child
        input
          border-top-left-radius: 4px
          border-bottom-left-radius: 4px
      &:last-child
        input
          border-top-right-radius: 4px
          border-bottom-right-radius: 4px
          border: 1px solid $om-light-grey2
</style>
